<template>
  <div class="flex flex-col items-center select-none">
    <div v-if="tb"
         class="relative"
    >
      <button class="h-[15vw] md:h-[3.75vw] rounded-full p-[2vw] md:p-[0.5vw] text-indigo flex items-center bg-white relative overflow-hidden md:hover:bg-white/90 duration-150"
              :class="tb.permission.value === 'denied' ? 'cursor-not-allowed' : ''"
              style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);-webkit-touch-callout: none;"
              :disabled="tb.permission.value === 'denied' || tb.state.value === TALKBACK_STATES.SETUP"
              @mousedown="tb.startRecording"
              @mouseup="tb.stopRecording"
              @touchstart="tb.startRecording"
              @touchend="tb.stopRecording"
              @mouseleave="tb.stopRecording"
              @blur="tb.stopRecording"
      >
        <div class="rounded-full h-[11vw] md:h-[2.75vw] absolute left-[2vw] md:left-[0.5vw] top-[2vw] md:top-[0.5vw] bottom-[2vw] md:bottom-[0.5vw]"
             :class="[tb.state.value === TALKBACK_STATES.RECORDING ? `w-[calc(100%-4vw)] md:w-[calc(100%-1vw)] right-[2vw] md:right-[0.5vw] ease-linear` : 'w-[11vw] md:w-[2.75vw] duration-500', {
               'bg-red': tb.state.value !== TALKBACK_STATES.PLAYING,
               'bg-indigo': tb.state.value === TALKBACK_STATES.PLAYING
             }]"
             :style="{ transitionDuration: tb.state.value === TALKBACK_STATES.RECORDING ? `${maxDuration}ms` : ''}"
        >
          <IconMicFill class="h-[6vw] w-[6vw] md:h-[1.5vw] md:w-[1.5vw] text-white absolute top-[2.5vw] bottom-[2.5vw] md:top-[0.625vw] md:bottom-[0.625vw] left-[2.5vw] md:left-[0.625vw] duraiton-300"
                       :class="tb.state.value !== TALKBACK_STATES.PLAYING ? 'opacity-100' : 'opacity-0'"
          />
          <IconPlayFill class="h-[6vw] w-[6vw] top-[2.5vw] bottom-[2.5vw] left-[2.5vw] md:h-[1.5vw] md:w-[1.5vw] text-white absolute md:top-[0.625vw] md:bottom-[0.625vw] md:left-[0.625vw]"
                        :class="tb.state.value === TALKBACK_STATES.PLAYING ? 'opacity-100' : 'opacity-0'"
          />
        </div>
        <div class="uppercase font-medium font-oswald tracking-[-0.01em] text-[7.5vw] md:text-[1.875vw] md:pl-[3.5vw] pl-[14vw] pr-[4vw] md:pr-[1vw] duration-500 select-none"
             :class="tb.state.value === TALKBACK_STATES.INACTIVE || tb.state.value === TALKBACK_STATES.SETUP ? 'translate-y-0' : 'md:translate-y-[4vw] translate-y-[16vw]'"
        >
          Talk like me!
        </div>

        <div class="absolute md:top-[0.8125vw] md:bottom-[0.8125vw] bottom-[3.25vw] top-[3.25vw] left-[16vw] md:left-[4vw] duration-500 md:w-[calc(100%-5.25vw)] w-[calc(100%-21vw)] select-none"
             :class="tb.state.value === TALKBACK_STATES.PLAYING ? 'translate-y-0' : 'md:translate-y-[-4vw] translate-y-[-16vw]'"
        >
          <svg viewBox="0 0 164 34"
               class="md:h-[2.125vw] h-[8.5vw] w-full"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <clipPath id="svgPathMask">
                <rect width="4"
                      height="34"
                      rx="2"
                      fill="#281E3C"
                /><rect x="8"
                        y="5"
                        width="4"
                        height="24"
                        rx="2"
                        fill="#281E3C"
                /><rect x="16"
                        width="4"
                        height="34"
                        rx="2"
                        fill="#281E3C"
                /><rect x="24"
                        y="9"
                        width="4"
                        height="16"
                        rx="2"
                        fill="#281E3C"
                /><rect x="32"
                        y="4"
                        width="4"
                        height="26"
                        rx="2"
                        fill="#281E3C"
                /><rect x="40"
                        y="6"
                        width="4"
                        height="22"
                        rx="2"
                        fill="#281E3C"
                /><rect x="48"
                        y="4"
                        width="4"
                        height="26"
                        rx="2"
                        fill="#281E3C"
                /><rect x="56"
                        width="4"
                        height="34"
                        rx="2"
                        fill="#281E3C"
                /><rect x="64"
                        y="5"
                        width="4"
                        height="24"
                        rx="2"
                        fill="#281E3C"
                /><rect x="72"
                        width="4"
                        height="34"
                        rx="2"
                        fill="#281E3C"
                /><rect x="80"
                        y="9"
                        width="4"
                        height="16"
                        rx="2"
                        fill="#281E3C"
                /><rect x="88"
                        y="4"
                        width="4"
                        height="26"
                        rx="2"
                        fill="#281E3C"
                /><rect x="96"
                        y="6"
                        width="4"
                        height="22"
                        rx="2"
                        fill="#281E3C"
                /><rect x="104"
                        y="4"
                        width="4"
                        height="26"
                        rx="2"
                        fill="#281E3C"
                /><rect x="112"
                        width="4"
                        height="34"
                        rx="2"
                        fill="#281E3C"
                /><rect x="120"
                        y="5"
                        width="4"
                        height="24"
                        rx="2"
                        fill="#281E3C"
                /><rect x="128"
                        width="4"
                        height="34"
                        rx="2"
                        fill="#281E3C"
                /><rect x="136"
                        y="9"
                        width="4"
                        height="16"
                        rx="2"
                        fill="#281E3C"
                /><rect x="144"
                        y="4"
                        width="4"
                        height="26"
                        rx="2"
                        fill="#281E3C"
                /><rect x="152"
                        y="6"
                        width="4"
                        height="22"
                        rx="2"
                        fill="#281E3C"
                /><rect x="160"
                        y="4"
                        width="4"
                        height="26"
                        rx="2"
                        fill="#281E3C"
                />
              </clipPath>
            </defs>
            <rect class="text-indigo"
                  width="100%"
                  height="100%"
                  fill="currentColor"
                  fill-opacity="0.15"
                  clip-path="url(#svgPathMask)"
            />
            <rect class="text-indigo"
                  :class="tb.state.value === TALKBACK_STATES.PLAYING && tb.playbackDuration.value !== 0 ? 'w-full ease-linear' : 'w-0'"
                  :style="{
                    'transition-duration': tb.state.value === TALKBACK_STATES.PLAYING ? tb.playbackDuration.value + 'ms' : '0ms'
                  }"
                  width="100%"
                  height="100%"
                  fill="currentColor"
                  fill-opacity="0.5"
                  clip-path="url(#svgPathMask)"
            />
          </svg>
        </div>
      </button>

      <!-- Transparent button to ask for permission -->
      <!--<button v-if="tb.permission.value === 'prompt'"
       class="absolute inset-0 w-full h-full rounded-full opacity-0"
       style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"
       @click="tb.requestPermission()"
       />-->
    </div>

    <div class="text-white text-[3.5vw] md:text-[0.8125vw] leading-normal mt-[2vw] md:mt-[0.5vw]  pointer-events-none select-none">
      {{ tb.state.value === TALKBACK_STATES.PLAYING ? 'Playing…' : '' }}
      {{ tb.state.value === TALKBACK_STATES.INACTIVE || tb.state.value === TALKBACK_STATES.RECORDING ? 'Hold the button to record your voice.' : '' }}
      {{ tb.state.value === TALKBACK_STATES.SETUP ? 'Setting up the player…' : '' }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { TALKBACK_STATES, useTalkback } from '@/composables/use-talkback';


type SectionCharacterTryMyVoice = {
  character: 'tom' | 'angela' | 'ben' | 'hank' | 'ginger' | 'becca';
}

const props = defineProps<SectionCharacterTryMyVoice>();

const maxDuration = 6000;
const tb = useTalkback(props.character, maxDuration);

</script>