import { ref, onMounted } from 'vue';
import { useNuxtApp } from '#imports';
import { PitchShifter } from 'soundtouchjs';

export const TALKBACK_STATES = {
  RECORDING: 'recording',
  SETUP: 'setup',
  PLAYING: 'playing',
  INACTIVE: 'inactive'
};

const characters = {
  tom: {
    rate: 1.1,
    pitchSemitones: 5
  },
  angela: {
    rate: 1.1,
    pitchSemitones: 7
  },
  hank: {
    rate: 1.1,
    pitchSemitones: 3
  },
  ben: {
    rate: 0.9,
    pitchSemitones: -3
  },
  ginger: {
    rate: 1.15,
    pitchSemitones: 6
  },
  becca: {
    rate: 1.1,
    pitchSemitones: 5.5
  }
};


const state = ref(TALKBACK_STATES.INACTIVE);
const playbackDuration = ref(0);
const permission = ref('prompt');

let shifter, blob, audioCtx, gain, fr, mediaRecorder, stopTimeout;

export function useTalkback(character, maxDurationMs) {
  const { $event } = useNuxtApp();

  onMounted(() => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.log('getUserMedia not supported on your browser!');
    }

    canUseMicrophone();

    fr = new FileReader();
    audioCtx = new (window.AudioContext || window.webkitAudioContext)({ sampleRate: 48000 });
    gain = audioCtx.createGain();
  });

  /**
   * Check if the user has granted permission to use the microphone
   * https://developer.mozilla.org/en-US/docs/Web/API/Permissions/query
   *
   * State can be: granted, denied, prompt
   * Supports all majors browsers (Safari 16+)
   *
   * onChange event does not work in Safari 16 (TP only) and on iOS, so it's not used
   *
   * @returns {void}
   */
  function canUseMicrophone() {
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: 'microphone' }).then(function (permissionStatus) {
        permission.value = permissionStatus.state;
      });
    } else {
      // Permission API not supported
      permission.value = 'prompt';
    }
  }

  /**
   * Request permission to use the microphone
   *
   * This will trigger a popup in the browser
   * A separate function is used to avoid starting the recording immediately
   * after the user has granted permission.
   */
  function requestPermission() {
    $event('talkback-permission', { props: { character } });
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(stream => {
          state.value = TALKBACK_STATES.SETUP;

          setTimeout(() => {
            state.value = TALKBACK_STATES.INACTIVE;
          }, 750);

          stream.getTracks().forEach(t => {
            t.stop();
          });

          permission.value = 'granted';
        })
        .catch(err => {
          permission.value = 'denied';
          return { err };
        });
    } else {
      permission.value = 'denied';
      return null;
    }
  }

  /**
   * Starts the recording process
   *
   * If the user has not granted permission yet, it will ask for permission first.
   * The difference is that when asking for permission, we stop the recording
   * right away, since the popup will not the fire the mouse leave, touch end,
   * and similar events, which leads to a poor user experience
   *
   * @returns {void}
   */
  function startRecording() {
    if (permission.value === 'prompt') {
      requestPermission();
      return;
    }

    // We don't want to start recording if we are already playing
    if (state.value === TALKBACK_STATES.PLAYING) {
      return;
    }

    $event('talkback-record', { props: { character } });

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        let chunks = [];
        mediaRecorder = new MediaRecorder(stream);

        if (mediaRecorder.state === TALKBACK_STATES.INACTIVE) {
          chunks = [];
          mediaRecorder.start();
          state.value = TALKBACK_STATES.RECORDING;

          mediaRecorder.ondataavailable = e => {
            chunks.push(e.data);
          };

          if (stopTimeout) {
            clearTimeout(stopTimeout);
          }

          stopTimeout = setTimeout(() => {
            stopRecording();
          }, maxDurationMs);
        }

        mediaRecorder.onstop = () => {
          blob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' });
          play();
        };
      })
      .catch(err => {
        console.error(`The following getUserMedia error occurred: ${err}`);
        return { err };
      });
  }

  function stopRecording() {
    if (state.value === TALKBACK_STATES.RECORDING) {
      state.value = TALKBACK_STATES.PLAYING;
      setTimeout(() => {
        if (stopTimeout) {
          clearTimeout(stopTimeout);
        }

        mediaRecorder.stop();
      }, 300);
    }
  }

  function play() {
    fr.readAsArrayBuffer(blob);

    fr.onloadend = () => {
      if (shifter) {
        shifter.off();
      }

      audioCtx.decodeAudioData(
        fr.result,
        audioBuffer => {
          playbackDuration.value = audioBuffer.duration * 1000 * (1 / characters[character].rate);

          shifter = new PitchShifter(audioCtx, audioBuffer, 256);
          shifter.rate = characters[character].rate;
          shifter.pitchSemitones = characters[character].pitchSemitones;

          state.value = TALKBACK_STATES.PLAYING;
          shifter.connect(gain);
          gain.connect(audioCtx.destination);
          audioCtx.resume();

          setTimeout(() => {
            shifter.disconnect(audioCtx.destination);
            state.value = TALKBACK_STATES.INACTIVE;

            // We reset the playback duration to 0 after 300ms
            // so outro animation of the playback bar can finish
            // If we do not reset it, quick playback will show a bit
            // of the playback bar from the previous playback
            setTimeout(() => {
              playbackDuration.value = 0;
            }, 300);
          }, audioBuffer.duration * 1000 * (1 / characters[character].rate));
        },
        () => {
          console.error('The following decodeAudioData error occurred');

          state.value = TALKBACK_STATES.INACTIVE;
        }
      );
    };
  }

  return { state, startRecording, stopRecording, playbackDuration, permission, requestPermission };
}
