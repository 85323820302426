<template>
  <div class="relative flex flex-col-reverse justify-between overflow-hidden md:flex-row"
       :class="`bg-${color}`"
  >
    <div class="md:pl-[13vw] md:pt-[7.25vw] z-10">
      <div class="px-[6vw] md:px-0">
        <div class="bg-indigo px-[6vw] md:px-[2.5vw] py-[10vw] md:py-[2.5vw] rounded-[5vw] md:rounded-[1.25vw] space-y-[5vw] md:space-y-[1vw] shadow-m flex flex-col md:min-w-[20vw]">
          <SectionCharacterTryMyVoicePill v-for="(item, index) in mp3s[character]"
                                          :key="index"
          >
            {{ item.label }}
          </SectionCharacterTryMyVoicePill>

          <!--<ButtonLefty v-for="(item, index) in mp3s[character]"
           :key="index"
           color="indigo"
           icon="PlayFill"
           class="self-start"
           background-color="white"
           @click="playSound(item.url)"
           >
           {{ item.label }}
           </ButtonLefty>-->
        </div>
      </div>

      <div class="pt-[6vw] md:pt-[2vw] pb-[12vw] md:pb-0 text-center">
        <div class="flex justify-center w-full">
          <SectionCharacterTryMyVoiceRecord :character="character" />
        </div>
      </div>
    </div>

    <NuxtImg format="auto"
              :src="image"
              class="w-[87vw] md:w-[34.8vw] pt-0 pb-[6vw] md:pb-0 md:pt-[5.6vw] relative z-10 md:mr-[17.5vw] object-contain mx-auto"
              sizes="xs:87vw sm:87vw md:34.8vw lg:34.8vw xl:34.8vw xxl:34.8vw"
    />

    <audio ref="audio" />

    <SvgShapeCloud class="absolute -left-[26vw] md:-left-[24vw] bottom-[104vw] md:-bottom-[72vw] w-[160vw] md:w-[151vw] rotate-[-10deg]"
                   :class="`text-${backgroundColor}`"
    />
    <div class="md:hidden absolute w-full h-[128vw] bottom-0 -mt-px"
         :class="`bg-${backgroundColor}`"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

type SectionCharacterTryMyVoice = {
  backgroundColor: string;
  color: string;
  character: 'tom' | 'angela' | 'ben' | 'hank' | 'ginger' | 'becca';
  image: string;
}

defineProps<SectionCharacterTryMyVoice>();

const audio = ref(null);

// function playSound(url: string) {
//   if (!audio.value) {
//     return;
//   }

// $event('character-play-sound', { props: { character: props.character, url } }})

//   audio.value.src = url;
//   audio.value.play();
// }

const mp3s = {
  becca: [
    {
      label: 'Sup',
      url: ''
    },
    {
      label: 'That’s wild!',
      url: ''
    },
    {
      label: 'Nice one',
      url: ''
    },
    {
      label: 'Bring it on!',
      url: ''
    }
  ],
  ginger: [
    {
      label: 'I’m ready!',
      url: ''
    },
    {
      label: 'Count me in!',
      url: ''
    },
    {
      label: 'Best friends ever',
      url: ''
    },
    {
      label: 'Woahhhhh',
      url: ''
    }
  ],
  hank: [
    {
      label: 'Hi, guys!',
      url: ''
    },
    {
      label: 'Oh boy',
      url: ''
    },
    {
      label: 'You’re the best!',
      url: ''
    },
    {
      label: 'Mmmm…',
      url: ''
    }
  ],
  ben: [
    {
      label: 'Salutations!',
      url: ''
    },
    {
      label: 'Fascinating',
      url: ''
    },
    {
      label: 'That was brilliant',
      url: ''
    },
    {
      label: 'You’ve done it!',
      url: ''
    },
    {
      label: 'Eureka!',
      url: ''
    }
  ],
  angela: [
    {
      label: 'Hello, #LittleKitties!',
      url: ''
    },
    {
      label: 'Dream big!',
      url: ''
    },
    {
      label: 'Let’s shine together',
      url: ''
    },
    {
      label: 'Excited',
      url: ''
    },
    {
      label: 'Cute',
      url: ''
    }
  ],
  tom: [
    {
      label: 'Hey, let’s do this!',
      url: ''
    },
    {
      label: 'That was TOM-mazing!',
      url: ''
    },
    {
      label: 'You got this!',
      url: ''
    },
    {
      label: 'Yessss!',
      url: ''
    }
  ]
};

// const mp3s = [
//   {
//     url: '/sounds/dog.mp3',
//     label: 'Hey, let’s do this'
//   },
//   {
//     url: '/sounds/rocket.mp3',
//     label: 'That was TOM-mazing!'
//   },
//   {
//     url: '/sounds/dog.mp3',
//     label: 'You got this!'
//   },
//   {
//     url: '/sounds/rocket.mp3',
//     label: 'Yyeeeesss'
//   }
// ];
</script>
